<template>
    <main class="inventory-page__content">
        <VContentHeaderDefault
            :title="'Справочник ' + $route.query.type"
            button="Добавить"
            @search="onSearch"
            @button="onCreateButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="inventory-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onInventoryClick"
                @click="onInventoryClick"
            ></VContentTableDefault>
        </template>

        <VInventoryAttributeModal :cache="{}"></VInventoryAttributeModal>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import VInventoryAttributeModal from './modals/VInventoryAttributeModal.vue';
import './InventoryPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import {
    paginateInventoryAttributes,
    createInventoryAttribute,
    updateInventoryAttribute,
} from '../../../js/inventory-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VContentTableDefault,
        VContentHeaderDefault,
        VInventoryAttributeModal,
    },
})
class InventoryPageAttributesWithType extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 100,
                label: 'Значение',
                display: (x) => x.value,
            },
        ],
    };

    onSearch(searchString) {
        this.page = 1;
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onInventoryClick(attribute) {
        try {
            const result = await this.$showModal(ModalNames.INVENTORY_ATTRIBUTE_CARD, {
                attribute: {
                    ...attribute,
                },
                editable: {
                    value: true,
                },
                visibleFields: {
                    value: true,
                },
            });

            this.isLoading = true;

            try {
                await updateInventoryAttribute(result);
                await this.fetchInventoryAttributes();
            } catch (error) {
                let message = errorMessage(error);

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onCreateButtonClick() {
        const valueType = this.$route.query.value_type;
        const searchable = Boolean(this.$route.query.searchable);

        if (!valueType) {
            return;
        }

        try {
            const attribute = await this.$showModal(ModalNames.INVENTORY_ATTRIBUTE_CARD, {
                attribute: {
                    type: this.$route.params.type,
                    value: '',
                    value_type: valueType,
                    searchable: searchable,
                },
                editable: {
                    value: true,
                },
                visibleFields: {
                    value: true,
                },
            });

            this.isLoading = true;

            try {
                await createInventoryAttribute(attribute);
                await this.fetchInventoryAttributes();
            } catch (error) {
                let message = errorMessage(error);

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    @Watch('page')
    @Watch('searchString')
    async fetchInventoryAttributes() {
        this.isLoading = true;

        try {
            const response = await paginateInventoryAttributes(this.page, this.searchString, 15, {
                whereType: this.$route.params.type,
            });

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchInventoryAttributes();
    }
}

export default InventoryPageAttributesWithType;
</script>
<style lang="scss"></style>
