<template>
    <VModalWrapper :name="name" :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="preset" v-slot="{ errors }">
                    <label class="active-form__label" for="preset">Шаблон</label>

                    <VSelect
                        id="preset"
                        name="preset"
                        label="preset_name"
                        class="active-form__control control"
                        placeholder="Без шаблона"
                        :class="{
                            error: errors[0],
                        }"
                        :filterable="false"
                        :search="onSearch"
                        :options="presets"
                        v-model="preset"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Продолжить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// svg
import TimesSvg from '@/images/times.svg';

// Components
import VInput from '../../../components/VInput/VInput.vue';
import VButton from '../../../components/VButton/VButton.vue';
import VSelect from '../../../components/VSelect/VSelect.vue';
import VModalWrapper from '../../../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../../../components/VContenteditable/VContenteditable.vue';
import VAttribute from '../components/VAttribute.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../../../js/enums/ModalNames';
import ModalMixin from '../../../../js/vue/mixins/ModalMixin';
import { paginateInventoryPresets } from '../../../../js/inventory-api';
import { debounce } from 'lodash';
import { errorMessage } from '../../../../js/error';

@Component({
    components: {
        TimesSvg,

        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,
        VAttribute,

        ValidationObserver,
        ValidationProvider,
    },
})
class VInventorySelectPresetModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.INVENTORY_SELECT_PRESET_CARD })
    name;
    @Prop({ type: String, default: 'Карточка создания оборудования' })
    defaultTitle;

    preset = null;
    default = null;

    presets = [];
    searchDebounce = null;

    async onSearch(search, loading) {
        if (!this.searchDebounce) {
            this.searchDebounce = debounce(async (search) => {
                loading(true);

                try {
                    await this.fetchPresets(search);
                } finally {
                    loading(false);
                    this.searchDebounce = null;
                }
            });
        }

        this.searchDebounce(search);
    }

    async fetchPresets(search = '') {
        try {
            const response = await paginateInventoryPresets(1, search);

            this.presets = response.data.data;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    submit() {
        this.resolve(this.preset ?? this.default);
        this.hide();
    }

    beforeOpen(event) {
        this.default = event?.params?.default;
    }

    beforeClose() {
        this.preset = null;
        this.default = null;
    }

    created() {
        this.fetchPresets();
    }
}

export default VInventorySelectPresetModal;
</script>
