<template>
    <div class="inventory-attribute-value">
        <template v-if="attribute.attribute_type || values?.length">
            <VSelect placeholder="Значение" label="value" :clearable="nullable" :options="values" v-model="attribute" />
        </template>

        <template v-else-if="attribute.value_type === 'string'">
            <VInput placeholder="Значение" v-model="attribute.value" @input="$emit('input', attribute)"></VInput>
        </template>
    </div>
</template>
<script>
// Components
import VInput from '../../../components/VInput/VInput.vue';
import VSelect from '../../../components/VSelect/VSelect.vue';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        VInput,
        VSelect,
    },
})
class VAttributeValue {
    @Prop({ type: Object, required: true })
    value;

    @Prop({ type: Array, default: () => [] })
    values;

    @Prop({ type: Boolean, default: false })
    nullable;

    get attribute() {
        return this.value;
    }

    set attribute(value) {
        this.$emit('input', value === null ? { ...this.attribute, value: null } : value);
    }
}

export default VAttributeValue;
</script>
<style lang="scss">
.inventory-attribute-value {
    width: 100%;
}
</style>
