<template>
    <VModalWrapper :name="name" :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }" v-if="attribute">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                    class="active-form__field"
                    name="type"
                    rules="required|max:64"
                    v-slot="{ errors }"
                    v-if="visibleFields.type"
                >
                    <label class="active-form__label active-form__label_required" for="attribute-type"
                        >Название справочника</label
                    >

                    <VSelect
                        id="attribute-type"
                        name="type"
                        class="active-form__control"
                        placeholder="Название справочника"
                        taggable
                        label="type"
                        :class="{
                            error: errors[0],
                        }"
                        :options="cache.attributes"
                        :disabled="editable?.type === false"
                        @option:selected="setAttribute"
                        v-model="selectedAttribute"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="valueType"
                    rules="required|max:64"
                    v-slot="{ errors }"
                    v-if="visibleFields.value_type && !selectedAttribute?.value_type"
                >
                    <label class="active-form__label active-form__label_required" for="attribute-valueType"
                        >Тип значения</label
                    >

                    <VSelect
                        id="attribute-valueType"
                        name="valueType"
                        label="name"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :disabled="editable?.value_type === false"
                        :options="valueTypes"
                        v-model="attribute.value_type"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="valueType"
                    rules="required|max:255"
                    v-slot="{ errors }"
                    v-if="visibleFields.value"
                >
                    <label
                        class="active-form__label"
                        :class="{ 'active-form__label_required': !nullable }"
                        for="attribute-value"
                        >Значение</label
                    >

                    <!-- input for validation -->
                    <input type="text" v-model="attribute.value" hidden v-if="!nullable" />
                    <VAttributeValue
                        :nullable="nullable"
                        :values="selectedAttribute?.values"
                        v-model="attribute"
                    ></VAttributeValue>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// svg
import TrashSvg from '@/images/trash.svg';

// Components
import VInput from '../../../components/VInput/VInput.vue';
import VButton from '../../../components/VButton/VButton.vue';
import VSelect from '../../../components/VSelect/VSelect.vue';
import VModalWrapper from '../../../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../../../components/VContenteditable/VContenteditable.vue';
import VAttributeValue from '../components/VAttributeValue.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../../../js/enums/ModalNames';
import ModalMixin from '../../../../js/vue/mixins/ModalMixin';
import InventoryAttributeValueTypes from '../../../../js/enums/InventoryAttributeValueTypes';

@Component({
    components: {
        TrashSvg,

        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,
        VAttributeValue,

        ValidationObserver,
        ValidationProvider,
    },
})
class VInventoryAttributeCreateModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.INVENTORY_ATTRIBUTE_CARD })
    name;
    @Prop({ type: String, default: 'Карточка создания атрибута' })
    defaultTitle;
    @Prop({ type: Object, required: true })
    cache;

    editable = {};
    nullable = false;
    attribute = null;
    selectedAttribute = null;
    visibleFields = {};

    valueTypes = InventoryAttributeValueTypes;

    setAttribute(attribute) {
        this.attribute = { ...this.attribute, type: attribute.type, value: '' };
    }

    submit() {
        this.resolve(this.attribute);
        this.hide();
    }

    beforeOpen(event) {
        this.nullable = event?.params?.nullable ?? false;
        this.editable = event?.params?.editable ?? {};
        this.attribute = event?.params?.attribute;
        this.visibleFields = event?.params?.visibleFields ?? {};
        this.selectedAttribute = null;
    }

    beforeClose() {
        this.editable = {};
        this.nullable = false;
        this.attribute = null;
        this.selectedAttribute = null;
    }
}

export default VInventoryAttributeCreateModal;
</script>
