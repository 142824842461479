var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"name":_vm.name,"title":_vm.title}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[(_vm.item)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [(_vm.item.qr_code_url)?_c('div',{staticClass:"inventory-item-modal__qr-code"},[_c('img',{staticClass:"inventory-item-modal__qr-code-image",attrs:{"src":_vm.item.qr_code_url,"alt":"qr-code"}}),_c('a',{staticClass:"button button_green",attrs:{"href":_vm.item.qr_code_url,"target":"_blank","download":"qr-code.png"}},[_vm._v(" Скачать QR-code")])]):_vm._e(),_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[(_vm.insert)?_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-count","rules":"required|numeric|min_value:1|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"item-count"}},[_vm._v("Количество")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"item-count","name":"item-count","placeholder":"Количество"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"item-name"}},[_vm._v("Название")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"item-name","name":"item-name","placeholder":"Название"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-cost","rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"item-cost"}},[_vm._v("Цена")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"item-cost","name":"item-cost","placeholder":"Цена"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.item.cost),callback:function ($$v) {_vm.$set(_vm.item, "cost", $$v)},expression:"item.cost"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-delivered_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"item-delivered_at"}},[_vm._v("Дата поставки")]),_c('FlatPickr',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-pause-resume-date","name":"resumeDate","placeholder":"Дата поставки","required":"","config":_vm.flatpickrConfig},model:{value:(_vm.item.delivered_at),callback:function ($$v) {_vm.$set(_vm.item, "delivered_at", $$v)},expression:"item.delivered_at"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-warranty_expire_at","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"item-warranty_expire_at"}},[_vm._v("Срок завершение гарантии")]),_c('FlatPickr',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-pause-resume-date","name":"resumeDate","placeholder":"Срок завершение гарантии","required":"","config":_vm.flatpickrConfig},model:{value:(_vm.item.warranty_expire_at),callback:function ($$v) {_vm.$set(_vm.item, "warranty_expire_at", $$v)},expression:"item.warranty_expire_at"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"item-responsible_person"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"item-responsible_person_id"}},[_vm._v("Материально ответственный")]),_c('VSelect',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"item-responsible_person_id","name":"responsible_person_id","label":"displayName","placeholder":"Материально ответственный","filterable":false,"options":_vm.cache.users,"reduce":(user) => user.id},model:{value:(_vm.item.responsible_person_id),callback:function ($$v) {_vm.$set(_vm.item, "responsible_person_id", $$v)},expression:"item.responsible_person_id"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_vm._l((_vm.item.attributes),function(attribute,index){return [_c('div',{key:index,staticClass:"active-form__field inventory-item-modal__attribute"},[_c('VAttribute',{attrs:{"cache":_vm.cache},model:{value:(_vm.item.attributes[index]),callback:function ($$v) {_vm.$set(_vm.item.attributes, index, $$v)},expression:"item.attributes[index]"}}),_c('VButton',{staticClass:"button_green-light inventory-item-modal__attribute-button inventory-item-modal__attribute-button--delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteAttribute(attribute, index)}}},[_c('TimesSvg')],1)],1)]}),_c('VButton',{staticClass:"button_green-light",attrs:{"type":"button"},on:{"click":_vm.addAttribute}},[_vm._v(" + Добавить атрибут ")]),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],2)]}}],null,false,3496515932)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }