var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VModalWrapper',_vm._g(_vm._b({attrs:{"name":_vm.name,"title":_vm.title}},'VModalWrapper',_vm.attributes,false),_vm.listeners),[(_vm.preset)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"active-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-preset_name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label active-form__label_required",attrs:{"for":"preset-preset_name"}},[_vm._v("Название шаблона")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"preset-preset_name","name":"preset-preset_name","placeholder":"Название шаблона"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.preset.preset_name),callback:function ($$v) {_vm.$set(_vm.preset, "preset_name", $$v)},expression:"preset.preset_name"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-preset_description","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-preset_description"}},[_vm._v("Описание шаблона")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"preset-preset_description","name":"preset-preset_description","placeholder":"Описание шаблона"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.preset.preset_description),callback:function ($$v) {_vm.$set(_vm.preset, "preset_description", $$v)},expression:"preset.preset_description"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-name","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-name"}},[_vm._v("Название")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"preset-name","name":"preset-name","placeholder":"Название"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.preset.name),callback:function ($$v) {_vm.$set(_vm.preset, "name", $$v)},expression:"preset.name"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-cost","rules":"double"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-cost"}},[_vm._v("Цена")]),_c('VInput',{staticClass:"active-form__control",class:{
                        error: errors[0],
                    },attrs:{"id":"preset-cost","name":"preset-cost","placeholder":"Цена"},on:{"submit":function($event){return handleSubmit(_vm.submit)}},model:{value:(_vm.preset.cost),callback:function ($$v) {_vm.$set(_vm.preset, "cost", $$v)},expression:"preset.cost"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-delivered_at"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-delivered_at"}},[_vm._v("Дата поставки")]),_c('FlatPickr',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-pause-resume-date","name":"resumeDate","placeholder":"Дата поставки","required":"","config":_vm.flatpickrConfig},model:{value:(_vm.preset.delivered_at),callback:function ($$v) {_vm.$set(_vm.preset, "delivered_at", $$v)},expression:"preset.delivered_at"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-warranty_expire_at"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-warranty_expire_at"}},[_vm._v("Срок завершение гарантии")]),_c('FlatPickr',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"request-pause-resume-date","name":"resumeDate","placeholder":"Срок завершение гарантии","required":"","config":_vm.flatpickrConfig},model:{value:(_vm.preset.warranty_expire_at),callback:function ($$v) {_vm.$set(_vm.preset, "warranty_expire_at", $$v)},expression:"preset.warranty_expire_at"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"active-form__field",attrs:{"name":"preset-responsible_person"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"active-form__label",attrs:{"for":"preset-responsible_person_id"}},[_vm._v("Материально ответственный")]),_c('VSelect',{staticClass:"active-form__control control",class:{
                        error: errors[0],
                    },attrs:{"id":"preset-responsible_person_id","name":"responsible_person_id","label":"displayName","placeholder":"Материально ответственный","filterable":false,"options":_vm.cache.users,"reduce":(user) => user.id},model:{value:(_vm.preset.responsible_person_id),callback:function ($$v) {_vm.$set(_vm.preset, "responsible_person_id", $$v)},expression:"preset.responsible_person_id"}}),_c('span',{staticClass:"active-form__error"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_vm._l((_vm.preset.attributes),function(attribute,index){return [_c('div',{key:index,staticClass:"active-form__field inventory-preset-modal__attribute"},[_c('VAttribute',{attrs:{"nullable":"","cache":_vm.cache},model:{value:(_vm.preset.attributes[index]),callback:function ($$v) {_vm.$set(_vm.preset.attributes, index, $$v)},expression:"preset.attributes[index]"}}),_c('VButton',{staticClass:"button_green-light inventory-preset-modal__attribute-button inventory-preset-modal__attribute-button--delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteAttribute(attribute, index)}}},[_c('TimesSvg')],1)],1)]}),_c('VButton',{staticClass:"button_green-light",attrs:{"type":"button"},on:{"click":_vm.addAttribute}},[_vm._v(" + Добавить атрибут ")]),_c('div',{staticClass:"active-form__actions"},[_c('VButton',{staticClass:"active-form__action button_green-light",attrs:{"type":"button"},on:{"click":_vm.hide}},[_vm._v(" Отмена ")]),_c('VButton',{staticClass:"active-form__action button_green",attrs:{"type":"submit"}},[_vm._v(" Сохранить ")])],1)],2)]}}],null,false,1775787207)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }