<template>
    <div class="inventory-attribute">
        <div class="inventory-attribute__type">
            {{ attribute.type }}
        </div>

        <VAttributeValue :nullable="nullable" :values="values" v-model="attribute"></VAttributeValue>
    </div>
</template>
<script>
// Components
import VInput from '../../../components/VInput/VInput.vue';
import VAttributeValue from './VAttributeValue.vue';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        VInput,
        VAttributeValue,
    },
})
class VAttribute {
    @Prop({ type: Object, required: true })
    cache;

    @Prop({ type: Object, required: true })
    value;

    @Prop({ type: Boolean, default: false })
    nullable;

    get values() {
        if (!this.attribute.attribute_type) {
            return [];
        }

        return this.cache.attributesMap[this.attribute.attribute_type].values;
    }

    get attribute() {
        return this.value;
    }

    set attribute(value) {
        this.$emit('input', value);
    }
}

export default VAttribute;
</script>
<style lang="scss">
.inventory-attribute {
    width: 100%;

    &__type {
        display: inline-block;
        margin: 0 0 8px 0;
        color: #808080;
        font-size: 0.9rem;
        line-height: 1rem;
    }
}
</style>
