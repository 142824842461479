<template>
    <main class="admin-page__content">
        <VContentHeaderDefault
            title="Города"
            button="Создать город"
            @search="onSearch"
            @button="onButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="admin-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onCityClick"
                @click="onCityClick"
            ></VContentTableDefault>
        </template>

        <VAdminCityCard></VAdminCityCard>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VAdminCityCard from '../../modals/VAdminCityCard.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import './AdminPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import { createCity, updateCity, getAllCities } from '../../../js/admin-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VAdminCityCard,
        VContentTableDefault,
        VContentHeaderDefault,
    },
})
class AdminPageCities extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 35,
                label: 'Название',
                display: (x) => x.name,
            },
            {
                size: 30,
                label: 'Часовой пояс',
                display: (x) => x.timezone,
            },
            {
                size: 20,
                label: 'Создан',
                display: (x) => this.$_toLocalDateHistory(x.createdAt),
            },
        ],
    };

    onSearch(searchString) {
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onCityClick(city) {
        try {
            city = await this.$showModal(ModalNames.ADMIN_PANEL_CITY_CARD, {
                city,
            });

            this.isLoading = true;

            try {
                await this.updateCity(city);
                await this.fetchCities();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.name) {
                    message = 'Город с таким именем уже существует';
                }

                if (error?.response?.data?.errors?.timezone) {
                    message = 'Неизвестный часовой пояс';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onButtonClick() {
        try {
            const city = await this.$showModal(ModalNames.ADMIN_PANEL_CITY_CARD, {
                city: {
                    name: '',
                    timezone: '',
                    defaultExecutorId: null,
                    defaultSecurityGroupId: null,
                },
            });

            this.isLoading = true;

            try {
                await this.createCity(city);
                await this.fetchCities();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.name) {
                    message = 'Город с таким именем уже существует';
                }

                if (error?.response?.data?.errors?.timezone) {
                    message = 'Неизвестный часовой пояс';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    createCity(user) {
        return createCity(user);
    }

    updateCity(user) {
        return updateCity(user);
    }

    @Watch('page')
    @Watch('searchString')
    async fetchCities() {
        this.isLoading = true;

        try {
            const response = await getAllCities(this.page, this.searchString);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchCities();
    }
}

export default AdminPageCities;
</script>
