<template>
    <main class="inventory-page__content">
        <VContentHeaderDefault
            title="Шаблоны"
            button="Добавить шаблон"
            @search="onSearch"
            @button="onCreateButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="inventory-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onInventoryClick"
                @click="onInventoryClick"
            ></VContentTableDefault>
        </template>

        <VInventoryPresetModal :cache="cache"></VInventoryPresetModal>
        <VInventoryAttributeModal :cache="cache"></VInventoryAttributeModal>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import VInventoryPresetModal from './modals/VInventoryPresetModal.vue';
import VInventoryAttributeModal from './modals/VInventoryAttributeModal.vue';
import './InventoryPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import {
    paginateInventoryPresets,
    updateInventoryPreset,
    getInventoryAttributeTypes,
    createInventoryPreset,
} from '../../../js/inventory-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';
import { getAllUsers } from '../../../js/admin-api';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VContentTableDefault,
        VContentHeaderDefault,
        VInventoryPresetModal,
        VInventoryAttributeModal,
    },
})
class InventoryPagePresets extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: false,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 45,
                label: 'Имя',
                display: (x) => x.preset_name,
            },
            {
                size: 50,
                label: 'Описание',
                display: (x) => x.preset_description,
            },
        ],
    };
    cache = {};

    onSearch(searchString) {
        this.page = 1;
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onInventoryClick(preset) {
        try {
            const result = await this.$showModal(ModalNames.INVENTORY_PRESET_CARD, {
                preset: { ...preset, attributes: [...preset.attributes] },
            });

            this.isLoading = true;

            try {
                await updateInventoryPreset(result);
                await this.fetchInventoryPresets();
            } catch (error) {
                let message = errorMessage(error);
                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onCreateButtonClick() {
        try {
            const result = await this.$showModal(ModalNames.INVENTORY_PRESET_CARD, {
                preset: {
                    preset_name: '',
                    preset_description: '',
                    responsible_person_id: '',
                    name: '',
                    cost: 0,
                    delivered_at: '',
                    warranty_expire_at: '',
                    attributes: [],
                },
            });

            this.isLoading = true;

            try {
                await createInventoryPreset(result);
                await this.fetchInventoryPresets();
            } catch (error) {
                let message = errorMessage(error);

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    @Watch('page')
    @Watch('searchString')
    async watcher() {
        this.isLoading = true;

        try {
            await this.fetchInventoryPresets();
        } finally {
            this.isLoading = false;
        }
    }

    async fetchRequiredData() {
        this.isLoading = true;

        try {
            await Promise.all([this.fetchInventoryPresets(), this.fetchAttributeTypes(), this.fetchUsers()]);
        } finally {
            this.isLoading = false;
        }
    }

    async fetchUsers() {
        try {
            const response = await getAllUsers(1, '', 100000);
            this.cache.users = response.data.data.map((user) => ({
                id: user.id,
                displayName: user.enabled ? user.displayName : user.displayName + ' (откл.)',
            }));
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    async fetchInventoryPresets(query = {}) {
        try {
            const response = await paginateInventoryPresets(this.page, this.searchString, 15, query);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    async fetchAttributeTypes() {
        try {
            const result = await getInventoryAttributeTypes({
                withAttributes: true,
            });

            this.cache.attributes = result.data.data;
            this.cache.attributesMap = this.cache.attributes.reduce((carry, attributeType) => {
                carry[attributeType.type] = attributeType;
                return carry;
            }, {});
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    created() {
        this.fetchRequiredData();
    }
}

export default InventoryPagePresets;
</script>
<style lang="scss"></style>
