<template>
    <VModalWrapper :name="name" :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="type" rules="required|max:64" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="attribute-type"
                        >Название справочника</label
                    >

                    <VInput
                        id="attribute-type"
                        name="attribute-type"
                        class="active-form__control"
                        placeholder="Название справочника"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="attributeType"
                    ></VInput>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// svg
import TrashSvg from '@/images/trash.svg';

// Components
import VInput from '../../../components/VInput/VInput.vue';
import VButton from '../../../components/VButton/VButton.vue';
import VSelect from '../../../components/VSelect/VSelect.vue';
import VModalWrapper from '../../../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../../../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../../../js/enums/ModalNames';
import InventoryAttributeValueTypes from '../../../../js/enums/InventoryAttributeValueTypes';
import ModalMixin from '../../../../js/vue/mixins/ModalMixin';

@Component({
    components: {
        TrashSvg,

        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VInventoryAttributeCreateModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.INVENTORY_ATTRIBUTE_CREATE_CARD })
    name;
    @Prop({ type: String, default: 'Карточка создания атрибута' })
    defaultTitle;

    attributeType = '';
    attributeSearchable = false;

    valueTypes = InventoryAttributeValueTypes;

    submit() {
        this.resolve({
            type: this.attributeType,
            title: '',
            value: '',
            value_type: 'string',
            searchable: this.attributeSearchable,
        });
        this.hide();
    }

    beforeOpen() {
        this.attributeType = '';
        this.attributeSearchable = false;
    }
}

export default VInventoryAttributeCreateModal;
</script>
