<template>
    <aside class="sidebar">
        <RouterLink
            :to="$route.query.prev ? $route.query.prev : '/request/inbox'"
            class="button_no-shadow button_without-padding sidebar__button-back"
            @click="goBack"
        >
            <ArrowLeftIcon class="sidebar__button-title" :size="20"> </ArrowLeftIcon>

            <span>Вернуться назад</span>
        </RouterLink>

        <div class="sidebar__logo">
            <RouterLink class="sidebar__logo-src" to="/"></RouterLink>
        </div>

        <ul class="sidebar__list">
            <li class="sidebar__item" v-for="(item, index) in $route.meta.sidebarItems" :key="index">
                <RouterLink :to="item.route" class="sidebar__button" sidebar>
                    <component class="sidebar__button-icon" :is="item.icon"></component>

                    <span> {{ item.label }} </span>
                </RouterLink>
            </li>
        </ul>
    </aside>
</template>
<script>
// Svg
// ...

// Other
import Vue from 'vue';
import Component from 'vue-class-component';

import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft';

@Component({
    components: {
        ArrowLeftIcon,
    },
})
class AdminMenu extends Vue {
    goBack() {
        this.$router.go(-1);
    }
}

export default AdminMenu;
</script>
<style lang="scss">
:root {
    --sidebar-button-active-color: var(--color-green);
    --sidebar-button-text-active-color: var(--color-white);
}

.router-link-active[sidebar],
.router-link-exact-active[sidebar] {
    font-weight: 500;
    color: var(--sidebar-button-text-active-color);
    background-color: var(--sidebar-button-active-color);
}

.sidebar {
    width: 100%;
    height: 100%;

    &__list {
        margin: 0;
    }

    &__item {
        margin: 0 24px;
    }

    &__button {
        display: flex;
        padding: 12px 15px;
        border-radius: 8px;
        font-size: 1.125rem;
        line-height: 1.3125rem;
        align-items: center;
        &-title {
            padding: 5px;
        }
    }

    &__button-icon {
        display: inline-block;
        width: 1.3125rem;
        height: 1.3125rem;
        margin-right: 10px;
    }

    &__button-back {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        justify-content: left;
        margin: 10px;
        color: var(--color-green);
        min-height: 40px;
        font-size: 0.95rem;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
            background-color: rgba(44, 44, 44, 0.12);
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 24px 24px 24px;
    }

    &__logo-src {
        display: block;
        width: 158px;
        height: 44px;
        background: url(~@/images/talan-logo.svg?url) center center no-repeat;
        background-size: 100%;
        z-index: 100;
    }
}
</style>
