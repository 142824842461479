<template>
    <main class="inventory-page__content">
        <VContentHeaderDefault
            title="Справочники"
            button="Создать справочник"
            @search="onSearch"
            @button="onCreateButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="inventory-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onAttributeClick"
                @click="onAttributeClick"
            ></VContentTableDefault>
        </template>

        <VInventoryAttributeCreateModal></VInventoryAttributeCreateModal>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import VInventoryAttributeCreateModal from './modals/VInventoryAttributeCreateModal.vue';
import './InventoryPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import { getInventoryAttributeTypes } from '../../../js/inventory-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VContentTableDefault,
        VContentHeaderDefault,
        VInventoryAttributeCreateModal,
    },
})
class InventoryPageAttributes extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 30,
                label: 'Название',
                display: (x) => x.type,
            },
            {
                size: 70,
                label: 'Значения',
                display: (x) => x.values,
            },
        ],
    };

    onSearch(searchString) {
        this.page = 1;
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    onAttributeClick(attribute) {
        this.$router.push({
            name: 'inventory.attributes.type',
            params: {
                type: attribute.type,
            },
            query: {
                prev: this.$route.fullPath,
                type: attribute.type,
                value_type: attribute.value_type,
                searchable: attribute.searchable,
            },
        });
    }

    async onCreateButtonClick() {
        try {
            const attribute = await this.$showModal(ModalNames.INVENTORY_ATTRIBUTE_CREATE_CARD);

            this.$router.push({
                name: 'inventory.attributes.type',
                params: {
                    type: attribute.type,
                },
                query: {
                    prev: this.$route.fullPath,
                    type: attribute.type,
                    value_type: attribute.value_type,
                    searchable: attribute.searchable,
                },
            });
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    @Watch('page')
    @Watch('searchString')
    async fetchInventoryAttributes() {
        this.isLoading = true;

        try {
            const response = await getInventoryAttributeTypes({
                withValues: true,
            });

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchInventoryAttributes();
    }
}

export default InventoryPageAttributes;
</script>
<style lang="scss"></style>
