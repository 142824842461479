import InventoryMenu from '../views/components/VSidebar.vue';
import InventoryPageItems from '../views/pages/Inventory/InventoryPageItems.vue';
import InventoryPagePresets from '../views/pages/Inventory/InventoryPagePresets.vue';
import InventoryPageAttributes from '../views/pages/Inventory/InventoryPageAttributes.vue';
import InventoryPageAttributesWithType from '../views/pages/Inventory/InventoryPageAttributesWithType.vue';
import oidc from '../vue-oidc-client';

import UserSvg from '@/images/user.svg';

const sidebarItems = [
    {
        icon: UserSvg,
        label: 'Инвентаризация',
        route: { name: 'inventory.items' },
    },

    {
        icon: UserSvg,
        label: 'Шаблоны',
        route: { name: 'inventory.presets' },
    },

    {
        icon: UserSvg,
        label: 'Справочники',
        route: { name: 'inventory.attributes' },
        subroutes: [
            {
                icon: UserSvg,
                route: { name: 'inventory.attributes.type' },
            },
        ],
    },
];

Object.freeze(sidebarItems);

export default [
    {
        name: 'inventory.items',
        path: '/inventory/items',
        components: {
            menu: InventoryMenu,
            default: InventoryPageItems,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems: sidebarItems,
        },
    },
    {
        name: 'inventory.attributes',
        path: '/inventory/attributes',
        components: {
            menu: InventoryMenu,
            default: InventoryPageAttributes,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems: sidebarItems,
        },
    },
    {
        name: 'inventory.attributes.type',
        path: '/inventory/attributes/:type',
        components: {
            menu: InventoryMenu,
            default: InventoryPageAttributesWithType,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems: sidebarItems,
        },
    },
    {
        name: 'inventory.presets',
        path: '/inventory/presets',
        components: {
            menu: InventoryMenu,
            default: InventoryPagePresets,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems: sidebarItems,
        },
    },
];
