<template>
    <div class="admin-page-header">
        <h1 class="admin-page-header__title">{{ title }}</h1>

        <div class="admin-page-header__control">
            <VSearchField
                class="admin-page-header__search"
                placeholder="Поиск"
                with-timeout
                @input="$emit('search', search)"
                v-model="search"
            ></VSearchField>

            <VButton class="button_green admin-page-header__button" @click="$emit('button')"> {{ button }} </VButton>
        </div>
    </div>
</template>
<script>
// Components
import VButton from './VButton/VButton.vue';
import VSearchField from './VInput/VSearchField.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        VButton,
        VSearchField,
    },
})
class VContentHeaderDefault extends Vue {
    @Prop({ type: String, default: 'Заголовок' })
    title;

    @Prop({ type: String, default: 'Создать' })
    button;

    search = '';
}

export default VContentHeaderDefault;
</script>
<style lang="scss">
.admin-page-header {
    margin: 24px 36px;

    &__control {
        display: flex;
    }

    &__search {
        flex-grow: 1;
        margin: 0 24px 0 0;
    }

    &__title {
        margin: 0 0 24px 0;
    }
}
</style>
