import AdminMenu from '../views/components/VSidebar.vue';
import AdminPageUsers from '../views/pages/Admin/AdminPageUsers.vue';
import AdminPageCities from '../views/pages/Admin/AdminPageCities.vue';
import AdminPageServices from '../views/pages/Admin/AdminPageServices.vue';
import AdminPageCategories from '../views/pages/Admin/AdminPageCategories.vue';
import AdminPageSecurityGroups from '../views/pages/Admin/AdminPageSecurityGroups.vue';
import oidc from '../vue-oidc-client';

import UserSvg from '@/images/user.svg';
import UsersSvg from '@/images/users.svg';
import CitiesSvg from '@/images/cities.svg';
import ServicesSvg from '@/images/services.svg';
import CategoriesSvg from '@/images/categories.svg';

const sidebarItems = [
    {
        icon: UserSvg,
        label: 'Пользователи',
        route: { name: 'admin-panel.users' },
    },
    {
        icon: CategoriesSvg,
        label: 'Категории',
        route: { name: 'admin-panel.categories' },
    },
    {
        icon: ServicesSvg,
        label: 'Сервисы',
        route: { name: 'admin-panel.services' },
    },
    {
        icon: CitiesSvg,
        label: 'Города',
        route: { name: 'admin-panel.cities' },
    },
    {
        icon: UsersSvg,
        label: 'Группы ответственных',
        route: { name: 'admin-panel.security-groups' },
    },
];

Object.freeze(sidebarItems);

export default [
    {
        name: 'admin-panel',
        path: '/admin',
        components: {
            menu: AdminMenu,
            default: AdminPageUsers,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
    {
        name: 'admin-panel.users',
        path: '/admin/users',
        components: {
            menu: AdminMenu,
            default: AdminPageUsers,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
    {
        name: 'admin-panel.cities',
        path: '/admin/cities',
        components: {
            menu: AdminMenu,
            default: AdminPageCities,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
    {
        name: 'admin-panel.services',
        path: '/admin/services',
        components: {
            menu: AdminMenu,
            default: AdminPageServices,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
    {
        name: 'admin-panel.categories',
        path: '/admin/categories',
        components: {
            menu: AdminMenu,
            default: AdminPageCategories,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
    {
        name: 'admin-panel.security-groups',
        path: '/admin/security-groups',
        components: {
            menu: AdminMenu,
            default: AdminPageSecurityGroups,
        },
        meta: {
            authName: oidc.authName,
            sidebarItems,
        },
    },
];
