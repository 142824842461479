<template>
    <main class="inventory-page__content">
        <VContentHeaderDefault
            title="Оборудование"
            button="Добавить оборудование"
            @search="onSearch"
            @button="onCreateButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="inventory-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onInventoryClick"
                @click="onInventoryClick"
            ></VContentTableDefault>
        </template>

        <VInventoryItemModal :cache="cache"></VInventoryItemModal>
        <VInventorySelectPresetModal></VInventorySelectPresetModal>
        <VInventoryAttributeModal :cache="cache"></VInventoryAttributeModal>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import PagePaginate from '../../components/PagePaginate/PagePaginate.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import VInventoryItemModal from './modals/VInventoryItemModal.vue';
import VInventoryAttributeModal from './modals/VInventoryAttributeModal.vue';
import VInventorySelectPresetModal from './modals/VInventorySelectPresetModal.vue';
import './InventoryPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import {
    paginateInventoryItems,
    createInventoryItems,
    updateInventoryItem,
    getInventoryAttributeTypes,
} from '../../../js/inventory-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';
import { getAllUsers } from '../../../js/admin-api';

@Component({
    components: {
        VSpinner,
        PagePaginate,
        VContentTableDefault,
        VContentHeaderDefault,
        VInventoryItemModal,
        VInventoryAttributeModal,
        VInventorySelectPresetModal,
    },
})
class InventoryPageItems extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 20,
                label: 'Имя',
                display: (x) => x.name,
            },
            {
                size: 10,
                label: 'Цена',
                display: (x) => x.cost,
            },
            {
                size: 15,
                label: 'Ответсвенный',
                display: (x) => x.responsible_person?.displayName ?? '-',
            },
            {
                size: 30,
                label: 'Атрибуты',
                className: 'admin-page-table__cell_pre-line',
                display: (x) =>
                    x.attributes.reduce(
                        (carry, attribute) => carry + attribute.type + ': ' + attribute.value + '\n',
                        '',
                    ),
            },
            {
                size: 10,
                label: 'Дата поставки',
                display: (x) => this.$_toLocalDateHistory(x.delivered_at),
            },
            {
                size: 10,
                label: 'Гарантийный срок',
                display: (x) => this.$_toLocalDateHistory(x.warranty_expire_at),
            },
        ],
    };
    cache = {};

    onSearch(searchString) {
        this.page = 1;
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onInventoryClick(item) {
        try {
            const result = await this.$showModal(ModalNames.INVENTORY_ITEM_CARD, {
                item: { ...item, attributes: [...item.attributes] },
            });

            this.isLoading = true;

            try {
                await updateInventoryItem(result);
                await this.fetchInventoryItems();
            } catch (error) {
                let message = errorMessage(error);
                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onCreateButtonClick() {
        try {
            const preset = await this.$showModal(ModalNames.INVENTORY_SELECT_PRESET_CARD, {
                default: {
                    responsible_person_id: '',
                    name: '',
                    cost: 0,
                    delivered_at: '',
                    warranty_expire_at: '',
                    attributes: [],
                },
            });

            const result = await this.$showModal(ModalNames.INVENTORY_ITEM_CARD, {
                insert: true,
                item: preset,
            });

            this.isLoading = true;

            try {
                const response = await createInventoryItems(result);
                await this.fetchInventoryItems({
                    whereId: response.data.map((item) => item.id),
                });
            } catch (error) {
                let message = errorMessage(error);

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    @Watch('page')
    @Watch('searchString')
    async watcher() {
        this.isLoading = true;

        try {
            await this.fetchInventoryItems();
        } finally {
            this.isLoading = false;
        }
    }

    async fetchRequiredData() {
        this.isLoading = true;

        try {
            await Promise.all([this.fetchInventoryItems(), this.fetchAttributeTypes(), this.fetchUsers()]);
        } finally {
            this.isLoading = false;
        }
    }

    async fetchUsers() {
        try {
            const response = await getAllUsers(1, '', 100000);
            this.cache.users = response.data.data.map((user) => ({
                id: user.id,
                displayName: user.enabled ? user.displayName : user.displayName + ' (откл.)',
            }));
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    async fetchInventoryItems(query = {}) {
        try {
            const response = await paginateInventoryItems(this.page, this.searchString, 15, query);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    async fetchAttributeTypes() {
        try {
            const result = await getInventoryAttributeTypes({
                withAttributes: true,
            });

            this.cache.attributes = result.data.data;
            this.cache.attributesMap = this.cache.attributes.reduce((carry, attributeType) => {
                carry[attributeType.type] = attributeType;
                return carry;
            }, {});
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        }
    }

    created() {
        this.fetchRequiredData();
    }
}

export default InventoryPageItems;
</script>
<style lang="scss"></style>
