<template>
    <VModalWrapper :name="name" :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }" v-if="item">
            <div class="inventory-item-modal__qr-code" v-if="item.qr_code_url">
                <img class="inventory-item-modal__qr-code-image" :src="item.qr_code_url" alt="qr-code" />

                <a class="button button_green" :href="item.qr_code_url" target="_blank" download="qr-code.png">
                    Скачать QR-code</a
                >
            </div>

            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                    class="active-form__field"
                    name="item-count"
                    rules="required|numeric|min_value:1|max_value:100"
                    v-slot="{ errors }"
                    v-if="insert"
                >
                    <label class="active-form__label active-form__label_required" for="item-count">Количество</label>

                    <VInput
                        id="item-count"
                        name="item-count"
                        class="active-form__control"
                        placeholder="Количество"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="count"
                    ></VInput>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="item-name"
                    rules="required|max:255"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="item-name">Название</label>

                    <VInput
                        id="item-name"
                        name="item-name"
                        class="active-form__control"
                        placeholder="Название"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="item.name"
                    ></VInput>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="item-cost"
                    rules="required|double"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="item-cost">Цена</label>

                    <VInput
                        id="item-cost"
                        name="item-cost"
                        class="active-form__control"
                        placeholder="Цена"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="item.cost"
                    ></VInput>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="item-delivered_at"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="item-delivered_at"
                        >Дата поставки</label
                    >

                    <FlatPickr
                        id="request-pause-resume-date"
                        name="resumeDate"
                        class="active-form__control control"
                        :class="{
                            error: errors[0],
                        }"
                        placeholder="Дата поставки"
                        required
                        :config="flatpickrConfig"
                        v-model="item.delivered_at"
                    ></FlatPickr>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider
                    class="active-form__field"
                    name="item-warranty_expire_at"
                    rules="required"
                    v-slot="{ errors }"
                >
                    <label class="active-form__label active-form__label_required" for="item-warranty_expire_at"
                        >Срок завершение гарантии</label
                    >

                    <FlatPickr
                        id="request-pause-resume-date"
                        name="resumeDate"
                        class="active-form__control control"
                        :class="{
                            error: errors[0],
                        }"
                        placeholder="Срок завершение гарантии"
                        required
                        :config="flatpickrConfig"
                        v-model="item.warranty_expire_at"
                    ></FlatPickr>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="item-responsible_person" v-slot="{ errors }">
                    <label class="active-form__label" for="item-responsible_person_id">Материально ответственный</label>

                    <VSelect
                        id="item-responsible_person_id"
                        name="responsible_person_id"
                        label="displayName"
                        class="active-form__control control"
                        placeholder="Материально ответственный"
                        :class="{
                            error: errors[0],
                        }"
                        :filterable="false"
                        :options="cache.users"
                        :reduce="(user) => user.id"
                        v-model="item.responsible_person_id"
                    />

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <template v-for="(attribute, index) in item.attributes">
                    <div class="active-form__field inventory-item-modal__attribute" :key="index">
                        <VAttribute :cache="cache" v-model="item.attributes[index]"></VAttribute>

                        <VButton
                            type="button"
                            class="button_green-light inventory-item-modal__attribute-button inventory-item-modal__attribute-button--delete"
                            @click="deleteAttribute(attribute, index)"
                        >
                            <TimesSvg></TimesSvg>
                        </VButton>
                    </div>
                </template>

                <VButton type="button" class="button_green-light" @click="addAttribute"> + Добавить атрибут </VButton>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Сохранить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// svg
import TimesSvg from '@/images/times.svg';

// Components
import VInput from '../../../components/VInput/VInput.vue';
import VButton from '../../../components/VButton/VButton.vue';
import VSelect from '../../../components/VSelect/VSelect.vue';
import VModalWrapper from '../../../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../../../components/VContenteditable/VContenteditable.vue';
import VAttribute from '../components/VAttribute.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../../../js/enums/ModalNames';
import ModalMixin from '../../../../js/vue/mixins/ModalMixin';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

@Component({
    components: {
        TimesSvg,

        VInput,
        VButton,
        VSelect,
        VModalWrapper,
        VContenteditable,
        VAttribute,

        ValidationObserver,
        ValidationProvider,
    },
})
class VInventoryItemModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.INVENTORY_ITEM_CARD })
    name;
    @Prop({ type: String, default: 'Карточка оборудования' })
    defaultTitle;
    @Prop({ type: Object, required: true })
    cache;

    item = null;
    count = 1;
    insert = false;

    flatpickrConfig = {
        altInput: true,
        altFormat: 'd.m.Y',
        dateFormat: 'Y-m-d',
        locale: Russian,
    };

    submit() {
        const count = parseInt(this.count, 10);
        this.resolve(this.insert ? new Array(count).fill(this.item) : this.item);
        this.hide();
    }

    async addAttribute() {
        try {
            const attribute = await this.$showModal(ModalNames.INVENTORY_ATTRIBUTE_CARD, {
                attribute: {
                    type: '',
                    value: '',
                    value_type: 'string',
                    searchable: false,
                },
                editable: {
                    type: true,
                    value: true,
                    value_type: true,
                    searchable: true,
                },
                visibleFields: {
                    type: true,
                    value: true,
                    value_type: true,
                    searchable: true,
                },
            });

            this.item.attributes.push(attribute);
        } catch (error) {
            return;
        }
    }

    deleteAttribute(_, index) {
        this.item.attributes.splice(index, 1);
    }

    beforeOpen(event) {
        this.item = event?.params?.item;
        this.count = 1;
        this.insert = event?.params?.insert;
    }

    beforeClose() {
        this.item = null;
    }
}

export default VInventoryItemModal;
</script>
<style lang="scss">
.inventory-item-modal {
    &__qr-code {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__attribute {
        position: relative;
    }

    &__attribute-button {
        &--delete {
            position: absolute;
            left: 100%;
            right: 0;
            bottom: 0;
            width: 32px;
            height: 32px;
            margin: 8px;
            padding: 0;

            & svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}
</style>
