import http from './http';

export function findInventoryItem(id) {
    return http.get(`/inventory/items/${id}`);
}

export function paginateInventoryItems(page, search = '', limit = 15, query = {}) {
    const params = { page, limit, ...query };

    if (search) {
        params.search = search;
    }

    return http.get(`/inventory/items`, { params });
}

export function createInventoryItem(item) {
    return http.post(`/inventory/items`, item);
}

export function createInventoryItems(items) {
    return http.post(`/inventory/items/bulk-insert`, items);
}

export function updateInventoryItem(item) {
    return http.put(`/inventory/items/${item.id}`, item);
}

//////////////////////////////////////////////////////////////////////////////////////////////////

export function findInventoryAttribute(id) {
    return http.get(`/inventory/attributes/${id}`);
}

export function paginateInventoryAttributes(page, search = '', limit = 15, query = null) {
    const params = { page, limit, ...query };

    if (search) {
        params.search = search;
    }

    return http.get(`/inventory/attributes`, { params });
}

export function getInventoryAttributeTypes(query = {}) {
    const params = query;

    return http.get(`/inventory/attributes/types`, { params });
}

export function createInventoryAttribute(item) {
    return http.post(`/inventory/attributes`, item);
}

export function createInventoryAttributes(items) {
    return http.post(`/inventory/attributes/bulk-insert`, items);
}

export function updateInventoryAttribute(item) {
    return http.put(`/inventory/attributes/${item.id}`, item);
}

//////////////////////////////////////////////////////////////////////////////////////////////////

export function findInventoryPreset(id) {
    return http.get(`/inventory/presets/${id}`);
}

export function paginateInventoryPresets(page, search = '', limit = 15, query = {}) {
    const params = { page, limit, ...query };

    if (search) {
        params.search = search;
    }

    return http.get(`/inventory/presets`, { params });
}

export function createInventoryPreset(preset) {
    return http.post(`/inventory/presets`, preset);
}

export function updateInventoryPreset(item) {
    return http.put(`/inventory/presets/${item.id}`, item);
}
