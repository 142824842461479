<template>
    <main class="admin-page__content">
        <VContentHeaderDefault
            title="Категории"
            button="Создать Категорию"
            @search="onSearch"
            @button="onButtonClick"
        ></VContentHeaderDefault>

        <template v-if="isLoading">
            <div class="admin-page__loader">
                <VSpinner></VSpinner>
            </div>
        </template>

        <template v-else>
            <VContentTableDefault
                :rows="rows"
                :page="page"
                :total="total"
                :options="options"
                @page="onPageChange"
                @edit="onCityClick"
                @click="onCityClick"
            ></VContentTableDefault>
        </template>

        <VAdminCategoryCard></VAdminCategoryCard>
    </main>
</template>
<script>
// Components
import VSpinner from '../../components/VProgress/VSpinner.vue';
import VAdminCategoryCard from '../../modals/VAdminCategoryCard.vue';
import VContentTableDefault from '../../components/VContentTableDefault.vue';
import VContentHeaderDefault from '../../components/VContentHeaderDefault.vue';
import './AdminPage.scss';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { errorMessage } from '../../../js/error';
import { createCategory, updateCategory, deleteCategory, getAllCategories } from '../../../js/admin-api';
import { Watch } from 'vue-property-decorator';
import ModalNames from '../../../js/enums/ModalNames';

@Component({
    components: {
        VSpinner,
        VAdminCategoryCard,
        VContentTableDefault,
        VContentHeaderDefault,
    },
})
class AdminPageCategories extends Vue {
    rows = [];
    page = 1;
    total = 1;
    isLoading = true;
    searchString = '';
    options = {
        editable: true,
        deletable: false,
        columns: [
            {
                size: 5,
                label: '№',
                display: (x) => x.id,
            },
            {
                size: 35,
                label: 'Название',
                display: (x) => x.title,
            },
            {
                size: 30,
                label: 'Описание',
                display: (x) => x.subtitle,
            },
            {
                size: 20,
                label: 'Создан',
                display: (x) => this.$_toLocalDateHistory(x.createdAt),
            },
        ],
    };

    onSearch(searchString) {
        this.searchString = searchString;
    }

    onPageChange(page) {
        this.page = page;
    }

    async onDelete(category) {
        this.isLoading = true;

        try {
            await this.deleteCategory(category);

            const index = this.rows.indexOf(category);

            this.rows.splice(index, 1);
        } catch (error) {
            let message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    async onCityClick(category) {
        try {
            category = await this.$showModal(ModalNames.ADMIN_PANEL_CATEGORY_CARD, {
                category,
            });

            this.isLoading = true;

            try {
                await this.updateCategory(category);
                await this.fetchCategories();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.title) {
                    message = 'Категория с таким именем уже существует';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    async onButtonClick() {
        try {
            const category = await this.$showModal(ModalNames.ADMIN_PANEL_CATEGORY_CARD, {
                category: {
                    title: '',
                    subtitle: '',
                },
            });

            this.isLoading = true;

            try {
                await this.createCategory(category);
                await this.fetchCategories();
            } catch (error) {
                let message = errorMessage(error);

                if (error?.response?.data?.errors?.title) {
                    message = 'Категория с таким именем уже существует';
                }

                this.$showModal(ModalNames.ERROR, { errorMessage: message });
            } finally {
                this.isLoading = false;
            }
        } catch (error) {
            // The modal was closed.
            return;
        }
    }

    deleteCategory(service) {
        return deleteCategory(service);
    }

    createCategory(user) {
        return createCategory(user);
    }

    updateCategory(user) {
        return updateCategory(user);
    }

    @Watch('page')
    @Watch('searchString')
    async fetchCategories() {
        this.isLoading = true;

        try {
            const response = await getAllCategories(this.page, this.searchString);

            this.rows = response.data.data;
            this.total = response.data.total;
        } catch (error) {
            const message = errorMessage(error);

            this.$showModal(ModalNames.ERROR, { errorMessage: message });
        } finally {
            this.isLoading = false;
        }
    }

    created() {
        this.fetchCategories();
    }
}

export default AdminPageCategories;
</script>
